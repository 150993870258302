<template>
  <ui-component-modal
    :modalTitle="'Edit channels'"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveSpace"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-narrow">
        <thead>
          <tr>
            <th width="50">Active</th>
            <th>Channel</th>
            <th>Public</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="channel in filteredEditChannels" :key="channel.ChannelId">
            <td width="50">
              <input
                type="checkbox"
                v-model="channel.IsActive"
                :checked="channel.IsActive"
              />
            </td>
            <td>
              <span>{{ channel.ChannelName }}</span>
            </td>
            <td>
              <input
                type="checkbox"
                v-model="channel.IsPublic"
                :disabled="!channel.IsActive"
                :checked="channel.IsPublic"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import spaceProvider from '@/providers/space'

export default {
  components: {},

  props: {
    meetingtypeId: {
      default: 0,
      type: Number,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      editChannels: [],
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      channelId: Number(this.$route.params.channelId),
      locationId: Number(this.$route.params.locationId),
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('spaceStore', ['space']),

    channels() {
      let channels = []
      if (this.locationState) {
        channels = this.locationState.Channels.filter(
          (c) => c.ChannelId === this.channelId
        )
      }
      return channels
    },

    filteredEditChannels() {
      let self = this
      return self.editChannels.filter(
        (ec) => ec.MeetingtypeId === self.meetingtypeId
      )
    },
  },

  created() {
    this.mSpace = JSON.parse(JSON.stringify(this.space))

    this.prepData()
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    checkSpaceOnChannel(channelId) {
      let result = false

      let channelIndex = this.mSpace.Channels.findIndex(
        (c) =>
          c.MeetingtypeId === this.meetingtypeId && c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = true
      }

      return result
    },

    checkSpaceActiveOnChannel(channelId) {
      let result = false

      let channelIndex = this.mSpace.Channels.findIndex(
        (c) =>
          c.MeetingtypeId === this.meetingtypeId && c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = this.mSpace.Channels[channelIndex].IsPublic
      }

      return result
    },

    prepData() {
      let self = this

      for (let j = 0; j < self.channels.length; j++) {
        self.editChannels.push({
          ChannelId: self.channels[j].ChannelId,
          ChannelName: self.channels[j].ChannelName,
          IsPublic: self.checkSpaceActiveOnChannel(self.channels[j].ChannelId),
          IsActive: self.checkSpaceOnChannel(self.channels[j].ChannelId),
          MeetingtypeId: self.meetingtypeId,
        })
      }
    },

    saveSpace() {
      let self = this
      self.isSaving = true
      self.isSavingSuccess = false
      self.isSavingError = false

      for (let j = 0; j < self.editChannels.length; j++) {
        let ec = self.editChannels[j]

        let channelIndex = self.mSpace.Channels.findIndex(
          (c) =>
            c.ChannelId === ec.ChannelId &&
            c.MeetingtypeId === self.meetingtypeId
        )
        if (channelIndex === -1 && ec.IsActive) {
          self.mSpace.Channels.push(ec)
        }

        if (channelIndex > -1 && !ec.IsActive) {
          self.mSpace.Channels.splice(channelIndex, 1)
        }

        if (channelIndex > -1 && ec.IsActive) {
          Vue.set(self.mSpace.Channels, channelIndex, ec)
        }
      }

      //self.mSpace.Channels = self.editChannels.filter((c) => c.IsActive)

      spaceProvider.methods
        .updateSpace(self.mSpace)
        .then((response) => {
          if (response.status === 200) {
            self.mSpace = response.data
            self.setSpace(self.mSpace)

            setTimeout(() => {
              self.$emit('spaceEdited', self.mSpace)
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error.response)
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
          self.isSavingSuccess = true
        })
    },
  },
}
</script>
